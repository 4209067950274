//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-44:_3984,_4352,_5236,_7668,_8420,_9363,_4864,_1424;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-44')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-44', "_3984,_4352,_5236,_7668,_8420,_9363,_4864,_1424");
        }
      }catch (ex) {
        console.error(ex);
      }